.@{steps-prefix-cls}-small {
  &.@{steps-prefix-cls}-horizontal:not(.@{steps-prefix-cls}-label-vertical)
    .@{steps-prefix-cls}-item {
    margin-right: 12px;

    .@{steps-prefix-cls}-rtl& {
      margin-right: 0;
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 0;

      .@{steps-prefix-cls}-rtl& {
        margin-left: 0;
      }
    }
  }
  .@{steps-prefix-cls}-item-icon {
    width: @steps-small-icon-size;
    height: @steps-small-icon-size;
    font-size: @font-size-sm;
    line-height: @steps-small-icon-size;
    text-align: center;
    border-radius: @steps-small-icon-size;
  }
  .@{steps-prefix-cls}-item-title {
    padding-right: 12px;
    font-size: @font-size-base;
    line-height: @steps-small-icon-size;
    &::after {
      top: @steps-small-icon-size / 2;
    }
    .@{steps-prefix-cls}-rtl& {
      padding-right: 0;
    }
  }
  .@{steps-prefix-cls}-item-description {
    color: @text-color-secondary;
    font-size: @font-size-base;
  }
  .@{steps-prefix-cls}-item-tail {
    top: 8px;
  }
  .@{steps-prefix-cls}-item-custom .@{steps-prefix-cls}-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: none;
    border: 0;
    border-radius: 0;
    > .@{steps-prefix-cls}-icon {
      font-size: @steps-small-icon-size;
      line-height: @steps-small-icon-size;
      transform: none;
    }
  }
}
